<template>
  <section
    class="hotlink page_header_space"
    :style="{ backgroundImage: `url( ${require('@/statics/img/about/bg.webp')} )` }"
  >

    <div class="title w1200">
      <h3 class="txt-dark_gray txt-bold ls1">相關單位</h3>
      <img src="@/statics/img/index/icon_title2.svg" alt="">
    </div>

    <div class="content w1200">
      <a
        v-for="link in linkArray"
        :key="link.id"
        :href="link.url"
        class="item col50"
        target="_blank"
        rel="noreferrer noopener"
      >
        <div class="col40">
          <img :src="link.img" alt="" class="logo">
        </div>
        <div class="col60">
          <p class="name txt-bold">{{ link.title }}</p>
          <p class="txt-dark_gray small">{{ link.content }}</p>
        </div>
      </a>
    </div>
  </section>
</template>

<script>
import img1 from '@/statics/img/link/logo1.jpg';
import img2 from '@/statics/img/link/logo2.jpg';
import img3 from '@/statics/img/link/logo3.jpg';
import img4 from '@/statics/img/link/logo4.jpg';

export default {
  name: 'HotlinkRelative',
  data() {
    return {
      linkArray: [
        {
          id: 1,
          url: 'https://www.nhi.gov.tw/',
          img: img1,
          title: '衛生福利部中央健康保險署',
          content: '標誌是以愛心及人結合，其意涵二代健保更用心、讓您更安心，象徵全民政府對全民醫療照顧之心。人和愛也表達：我愛健保、有你真好；健保是你我的寶，二代健保品質更好的蘊涵。',
        },
        {
          id: 2,
          url: 'https://www.hpa.gov.tw/Home/Index.aspx',
          img: img2,
          title: '衛生福利部國民健康署',
          content: '本署以提升健康識能，力行健康生活型態；普及預防保健服務，推廣有效預防及篩檢；提升健康照護品質，改善慢性病控制及預後；營造友善支持環境，增進健康選擇及公平等4大方向...',
        },
        {
          id: 3,
          url: 'https://dep.mohw.gov.tw/DOLTC/mp-123.html',
          img: img3,
          title: '長期照護司',
          content: '隨著人口老化及照顧服務需求多元化，為因應失能、失智人口增加所衍生之長照需求，提供從支持家庭、居家、社區到住宿式照顧之多元連續服務，建立以社區為基礎之長照服務體系。',
        },
        {
          id: 4,
          url: 'https://1966.gov.tw/LTC/mp-201.html',
          img: img4,
          title: '長照政策專區',
          content: '長照2.0為了實現在地老化，提供從支持家庭、社區到住宿式照顧之多元連續服務，普及照顧服務體系，建立以社區為基礎的照顧型社區，期能提升具長期照顧需求者與照顧者的生活品質。',
        },
        {
          id: 5,
          url: 'https://www.nhi.gov.tw/QueryN/Query_HomeHealth.aspx',
          img: img1,
          title: '居家醫療整合照護特約醫事機構查詢',
          content: '標誌是以愛心及人結合，其意涵二代健保更用心、讓您更安心，象徵全民政府對全民醫療照顧之心。人和愛也表達：我愛健保、有你真好；健保是你我的寶，二代健保品質更好的蘊涵。',
        },
        {
          id: 6,
          url: 'https://www.nhi.gov.tw/query/query2.aspx?n=6A7346FAE90CAF3F&sms=36A0BB334ECB4011&topn=D39E2B72B0BDFA15',
          img: img1,
          title: '支付標準',
          content: '標誌是以愛心及人結合，其意涵二代健保更用心、讓您更安心，象徵全民政府對全民醫療照顧之心。人和愛也表達：我愛健保、有你真好；健保是你我的寶，二代健保品質更好的蘊涵。',
        },
      ],
    };
  },
};
</script>
